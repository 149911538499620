import React from "react"
import LabeledIconButton from "../LabeledIconButton";

function About (props){
    //social media links go here:
    const youtubeURL = "https://www.youtube.com/channel/UCS175q92DeSe56OxGybrjTg"
    const instagramURL = "https://www.instagram.com/__finlay/"
    const twitterURL = "https://twitter.com/__finlay"
    const itchURL = "https://finlaypaterson.itch.io/"
    const appStoreURL = "https://itunes.apple.com/us/developer/finlay-paterson/id1434325498?mt=8"
    const playStoreURL = "https://play.google.com/store/apps/developer?id=Finlay+Paterson"
    
    // path for local images, get passed using props and used in IconButton.js
    const youtubeImg = "/img/ytIcn.png"
    const instagramImg = "/img/instIcn.png"
    const twitterImg = "/img/twIcn.png"
    const itchImg = "/img/itIcn.png"
    const appStoreImg = "/img/apIcn.png"
    const playImg = "/img/playIcn.png"

    //static text for each paragraph
    const paragraphA = "I am an artist and developer from Toronto, Canada with a background "+
                       "in 3D modelling and animation.  My work incorporates visual art and programming "+
                       "to create interactive content including games, sculpture, installations, apps and "+
                       "web experiences."

    const paragraphB1 = "I have released multiple games and apps for iOS and Android devices, including the puzzle game " 




    
    const paragraphB2 = " as well as the endless runner "
    
    const paragraphB3 = ". In 2018 I released a cycling simulation game called "
    
    const paragraphB4 = " which has over 2 million downloads.  "
    const paragraphB5 =  "I am currently working on a new game called "
    const paragraphB6 = " which is currently in "
    const paragraphB7 = ", and will be released later this year."

    const paragraphC = "My areas of interest are; procedural & generative design, VR, AR, UGC, AI "+
                       "(gaming), computer vision, emergent play, online games, level & environment "+
                       "design, location based gaming, games inclusivity, games as art, 3D Printing, "+
                       "industrial design, manufacturing."

    return(
        <div className={ props.darkMode ?  "page pageDark" : "page" }>       
            <div className={ props.darkMode ? "myAboutPage myAboutPageDark" :  "myAboutPage" }>                
                <div className={props.darkMode ? "aboutParagraph aboutParagraphDark" : "aboutParagraph" }>
                    <p>
                        {paragraphA}
                    </p><p>
                        {/* <Text>My paragraph has <Text onPress={...} style={{color:"red", textDecorationLine: "underline"}}>a link</Text> in it</Text> */}
                            { paragraphB1 } 
                                <a className={ props.darkMode ?  "linkTextDark linkText" : "linkText"}  href="https://itunes.apple.com/us/app/vinecraft/id1450595833">Vinecraft</a>

                            { paragraphB2 } 
                                <a className={ props.darkMode ?  "linkTextDark linkText" : "linkText"}  href="https://itunes.apple.com/us/app/throughline/id1448761577">Throughline</a>
                            { paragraphB3 }
                                <a className={ props.darkMode ?  "linkTextDark linkText" : "linkText"}  href="https://itunes.apple.com/us/app/alleycat/id1441479753">Alleycat</a>

                                { paragraphB4 }
                              
                         
                                { paragraphB5 }
                                <a className={ props.darkMode ?  "linkTextDark linkText" : "linkText"}  href="https://www.finlaypaterson.ca/secretdoor">Secret Door</a>
                                { paragraphB6 }
                                <a className={ props.darkMode ?  "linkTextDark linkText" : "linkText"}  href="https://www.finlaypaterson.ca/secretdoor">Public Beta</a>
                                { paragraphB7 }
                                
                    </p><p>
                        {paragraphC}
                    </p>
              </div>
            </div>
            <br/>

            <br/>
            <div className="socialLinkBox">   
                    <LabeledIconButton darkMode={props.darkMode} labelText="Instagram" linkURL={instagramURL} imgURL={instagramImg}/>
                    <LabeledIconButton darkMode={props.darkMode} labelText="Youtube" linkURL={youtubeURL} imgURL={youtubeImg}/>
                    <LabeledIconButton darkMode={props.darkMode} labelText="Twitter" linkURL={twitterURL} imgURL={twitterImg}/>
                    <LabeledIconButton darkMode={props.darkMode} labelText="itch.io" linkURL={itchURL} imgURL={itchImg}/>
                    <LabeledIconButton darkMode={props.darkMode} labelText="App Store" linkURL={appStoreURL} imgURL={appStoreImg}/>
                    <LabeledIconButton darkMode={props.darkMode} labelText="Play Store" linkURL={playStoreURL} imgURL={playImg}/>
            </div>
            <br/>
            <br/>


            <div className={props.darkMode ? "myAboutPageBox myAboutPageBoxDark" : "myAboutPageBox" }>                
                <div className="allignCenter">
                    <div className={props.darkMode ? "aboutParagraph aboutParagraphDark" : "aboutParagraph" }>
                        Join my maliing list to receive updates about my future projects and games.
                    <br/>
                    <br/>


                        <form action="https://formsubmit.co/21e9d098d60348cfaae96ac150642585" method="POST">                           
                            <input  type="hidden" id="SignupType" name="SignupType" value="Mailling List"></input>
                            <input type="email" 

                            className={props.darkMode ?"inputBox inputBoxSquare inputBoxSquareDark" : "inputBox inputBoxSquare" }
                            name="email" placeholder="Enter your Email Address" required></input>
                            <button type="submit" 
                            className={props.darkMode ?"button buttonSquare buttonSquareDark" : "button buttonSquare" }
                            
                            >Submit</button>
                        </form>  
                    </div>


             
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <a href="mailto:finlaypatersongames@gmail.com">
                <div className={props.darkMode ? "myAboutPageBox myAboutPageBoxDark" : "myAboutPageBox" }>                
                    <div className={props.darkMode ? "infoBox infoBoxDark" : "infoBox" }>
                        <div className={props.darkMode ? "aboutParagraph aboutParagraphDark" : "aboutParagraph" }> 
                            <div className="inlineDescription"> For other inquiries please contact me at </div>
                            <div className="inlineDescription"><b>finlaypatersongames@gmail.com</b>  </div>
                        </div>
                    </div>
                </div>
            </a>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        )
    }

export default About