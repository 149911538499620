import React from "react"
import SingleImage from "../SingleImage"
import DoubleImage from "../DoubleImage"
import TripleImage from "../TripleImage"
// import QuadImage from "../QuadImage"

function Jewelry (props){
    const jewelryImg0 = "/img/jewelry_00.jpg"
    const jewelryImg1 = "/img/jewelry_01.jpg"
    const jewelryImg2 = "/img/jewelry_02.jpg"
    const jewelryImg3 = "/img/jewelry_03.jpg"
    const jewelryImg4 = "/img/jewelry_04.jpg"
    const jewelryImg5 = "/img/jewelry_05.jpg"
    const jewelryImg6 = "/img/jewelry_06.jpg"
    const jewelryImg7 = "/img/jewelry_07.jpg"
    const jewelryImg8 = "/img/jewelry_08.jpg"
    const jewelryImg9 = "/img/jewelry_09.jpg"
    const jewelryImg10 = "/img/jewelry_10.jpg"
    const jewelryImg11 = "/img/jewelry_11.jpg"
    const jewelryImg12 = "/img/jewelry_12.jpg"
    const jewelryImg13 = "/img/jewelry_13.jpg"
    const jewelryImg14 = "/img/jewelry_14.jpg"
    const jewelryImg15 = "/img/jewelry_15.jpg"


    return(
        <div className={ props.darkMode ?  "page pageDark" : "page" }> 
            <div >
                <div className= { props.darkMode ? "myArtPage myArtPageDark" :  "myArtPage" }>                 
                    {/* <h1 className={props.darkMode ? "h1Dark" : ""} >Jewelry</h1> */}
                    {/* <p className={props.darkMode ? "txt txtDark" : "txt" } >
                    </p> */}
                             
                        <TripleImage  
                            imgURLA={jewelryImg0} 
                            imgURLB={jewelryImg1} 
                            imgURLC={jewelryImg2} 
                        />   
                        <SingleImage 
                            imgURL={jewelryImg3} 
                            />  
                        <TripleImage  
                            imgURLA={jewelryImg4} 
                            imgURLB={jewelryImg5} 
                            imgURLC={jewelryImg6} 
                        />       
                        <SingleImage 
                            imgURL={jewelryImg7} 
                            />     
                        <DoubleImage 
                            imgURLA={jewelryImg8}
                            imgURLB={jewelryImg9}
                            />
                        <DoubleImage 
                            imgURLA={jewelryImg10}
                            imgURLB={jewelryImg11}
                            />
                        <SingleImage 
                            imgURL={jewelryImg12} 
                            />   
                        <DoubleImage 
                            imgURLA={jewelryImg13}
                            imgURLB={jewelryImg14}
                            />
                        <SingleImage 
                            imgURL={jewelryImg15} 
                            />                  
                <br/>                    
                </div>
            </div>
            <div className={props.darkMode ? "myAboutPageBox myAboutPageBoxDark" : "myAboutPageBox" }>                
                <div className={props.darkMode ? "infoBox infoBoxDark" : "infoBox" }>
                    <div className={props.darkMode ? "aboutParagraph aboutParagraphDark" : "aboutParagraph" }> 
                        <div className="inlineDescription"> For inquiries please contact me at </div>
                        <div className="inlineDescription"><b>finlaypatersonjewelry@gmail.com</b>  </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
        )}
export default Jewelry