import React from "react"
import GameInfoPortrait from "../GameInfoPortrait"
//import GameInfoLandscape from "../GameInfoLandscape"
import GameInfoLandscapeB from "../GameInfoLandscapeB"
import GameInfoLandscapeC from "../GameInfoLandscapeC"

// const doorIcon = window.location.origin + "/img/secretdoor.svg"

//game zero ICEBREAKER
const game0name = "IceBreaker"
const game0downloadUrl = ""
const game0trailerUrl = "https://www.youtube.com/watch?v=MObEUnbcBSM"
const game0description = "Icebreaker is a colourful cyberpunk adventure with a casual difficulty aimed at putting the player in " + 
                         "a flow state while they tunnel deeper into the visuals and story. Players tap on the yellow panels to " + 
                         "advance, and must work quickly and accurately to keep the connection going. On their travels " + 
                         "players will also hit firewalls, which are simple puzzles that slow them down. The player's path is " + 
                         "traced out on the board as components are activated."
const game0poster = "/img/game0_poster.jpg"
const game0img0 = "/img/game0_img1.jpg"
const game0img1 = "/img/game0_img2.jpg"
const game0img2 = "/img/game0_img3.jpg"
const game0img3 = "/img/game0_img4.jpg"
const game0img4 = "/img/game0_img5.jpg"
const game0img5 = "/img/game0_img6.jpg"
const game0img6 = "/img/game0_img7.jpg"
const game0img7 = "/img/game0_img8.jpg"

//game one TORONTO RACCOONS
const game1name = "Toronto Raccoons"
const game1downloadUrl = ""
const game1trailerUrl = "https://www.youtube.com/watch?v=MnadocX0FJs&t=71s"
const game1description = "Toronto Raccoons is a GPS scavenger hunt game, where players must travel to a series of real world locations " + 
                        "to find an object in each one. The game is played on the users smartphone, and uses GPS to show their location in " +
                        "the city and where the target locations are. Once a player arrives at a location, in this case they are city parks, " +
                        "the player is able to play through the level and hunt for the object. Each level is modelled after the park its based " +
                        "on using on satellite imagery to keep everything in proportion, and helps the users see the city from another " +
                        "perspective. The play time for each level is kept short to ensure that players don't spend all their time at the park " +
                        "staring at a phone."
const game1poster = "/img/game1_poster.jpg"
const game1img0 = "/img/game1_img1.jpg"
const game1img1 = "/img/game1_img2.jpg"
const game1img2 = "/img/game1_img3.jpg"
const game1img3 = "/img/game1_img4.jpg"
const game1img4 = "/img/game1_img5.jpg"
const game1img5 = "/img/game1_img6.jpg"
const game1img6 = "/img/game1_img7.jpg"
const game1img7 = "/img/game1_img8.jpg"

//game two Vinecraft
const game2name = "Vinecraft"
const game2downloadUrl = "https://itunes.apple.com/us/app/vinecraft/id1450595833"
const game2trailerUrl = "https://www.youtube.com/watch?v=HgiNmE7B3Ys"
const game2description = "Vinecraft is a puzzle game where players must grow a plant to the top of a wall to clear 100 levels of increasing " + 
                         "difficulty.  To grow the plant, players drag and drop tetromino shaped pieces that connect to the previously placed " + 
                         "piece.  A task which becomes increasingly difficult as the available space to play in is reduced level by level.  In " + 
                         "Vinecraft players play at their own pace, focusing on cleverness rather than reflexes."
const game2poster = "/img/game2_poster.jpg"
const game2img0 = "/img/game2_img1.jpg"
const game2img1 = "/img/game2_img2.jpg"
const game2img2 = "/img/game2_img3.jpg"
const game2img3 = "/img/game2_img4.jpg"
const game2img4 = "/img/game2_img5.jpg"
const game2img5 = "/img/game2_img6.jpg"
const game2img6 = "/img/game2_img7.jpg"
const game2img7 = "/img/game2_img8.jpg"

//game three Throughline
const game3name = "Throughline"
const game3downloadUrl = "https://itunes.apple.com/us/app/throughline/id1448761577"
const game3trailerUrl = "https://www.youtube.com/watch?v=Q0ATFiUgs50"
const game3description = "Throughline is a hyper-casual game for mobile devices where players control the position of a small dot on the screen " + 
                         "and help it avoid oncoming obstacles. As the game progresses the speed increases until it becomes a fast, twitch based " + 
                         "endless runner game. Players compete for the top spot on the online leaderboards to see who has the best reflexes. The game " + 
                         "makes use of a procedural generation system that builds the level out of a series of tiles allowing for easy runtime generation " + 
                         "with a variety of content."
const game3poster = "/img/game3_poster.jpg"
const game3img0 = "/img/game3_img1.jpg"
const game3img1 = "/img/game3_img2.jpg"
const game3img2 = "/img/game3_img3.jpg"
const game3img3 = "/img/game3_img4.jpg"
const game3img4 = "/img/game3_img5.jpg"
const game3img5 = "/img/game3_img6.jpg"
const game3img6 = "/img/game3_img7.jpg"
const game3img7 = "/img/game3_img8.jpg"

//game four ALLEYCAT
const game4name = "Alleycat"
const game4downloadUrl = "https://itunes.apple.com/us/app/alleycat/id1441479753"
const game4trailerUrl = "https://www.youtube.com/watch?v=-9eIPYEBCy8"
const game4description = "Alleycat is a mobile racing simulation designed to emulate the experience of racing through traffic in a real life alleycat bike " + 
                         "race. The race is made up of destinations with no rules for which path to take, racers must navigate the busy streets from one " + 
                         "checkpoint to the next as quickly as possible. It takes place in a procedurally generated open world, which is randomized each " + 
                         "time the players ride ends in an crash. Rather than learn the city, players must learn to read the traffic and find the openings. " + 
                         "Even parked cars pose a threat, to avoid getting doored or run over players will need to move quickly and learn to go with the flow."
const game4poster = "/img/game4_poster.jpg"
const game4img0 = "/img/game4_img1.jpg"
const game4img1 = "/img/game4_img2.jpg"
const game4img2 = "/img/game4_img3.jpg"
const game4img3 = "/img/game4_img4.jpg"
const game4img4 = "/img/game4_img5.jpg"
const game4img5 = "/img/game4_img6.jpg"
const game4img6 = "/img/game4_img7.jpg"
const game4img7 = "/img/game4_img8.jpg"

//game five SLIPPY CARS
const game5name = "Slippy Cars"
const game5downloadUrl = "https://itunes.apple.com/us/app/slippy-cars/id1046145748"
const game5trailerUrl = "https://www.youtube.com/watch?v=CXtt-GFKlo4"
const game5description = "Slippy Cars is a top down racing game set in an open world. Players can race on over 250 tracks around the city, or earn money at " + 
                         "one of several jobs from delivering pizza to driving an ambulance. Players can purchase new vehicles, and modify them with new paint " + 
                         "colours, faster engines, racing tires, and better suspension.  For a whole new perspective on the city, Slippy Cars also has a VR mode " + 
                         "which allows the player to use a controller to drive the car from behind the drivers seat."
const game5poster = "/img/game5_poster.jpg"
const game5img0 = "/img/game5_img1.jpg"
const game5img1 = "/img/game5_img2.jpg"
const game5img2 = "/img/game5_img3.jpg"
const game5img3 = "/img/game5_img4.jpg"
const game5img4 = "/img/game5_img5.jpg"
const game5img5 = "/img/game5_img6.jpg"
const game5img6 = "/img/game5_img7.jpg"
const game5img7 = "/img/game5_img8.jpg"
const game5img8 = "/img/game5_img9.jpg"

//game six SECRET DOOR
const game6name = "Secret Door"
const game6downloadUrl = "https://www.finlaypaterson.ca/secretdoor"
const game6trailerUrl = "https://www.youtube.com/watch?v=FiZBmjXmP38"
const game6description = "Secret Door is a 3rd Person Open-World Narrative Adventure Game coming first to iOS and Android, then later on desktop."
const game6poster = "/img/game6_poster.jpg"
const game6img0 = "/img/Screenshot_28.jpg"
const game6img1 = "/img/Screenshot_06.jpg"
const game6img2 = "/img/Screenshot_11.jpg"
const game6img3 = "/img/Screenshot_29.jpg"
const game6img4 = "/img/Screenshot_23.jpg"
const game6img5 = "/img/Screenshot_04.jpg"
// const game6img6 = "/img/game6_img7.jpg"
// const game6img7 = "/img/game6_img8.jpg"













// //game seven Go Board
// const game7name = "Go Board"
// const game7downloadUrl = ""
// const game7trailerUrl = "https://www.youtube.com"
// const game7description = "Go Board is an iOS version of the classic board game GO that uses turn based multiplayer to allow for asynchronous gameplay.  Players receive push " +
//                          "notifications when a its their turn to play, allowing for games to play out in a convenient manner.  Go is the ancient Chinese game of strategy where " +
//                          "two players compete to try to capture the most territory.   Players can place a stone on any unoccupied territory on the board, but if that stone is " +
//                          "surrounded by the opposing player it will be removed.  Removed stones count as one point, and players also receive one point for each area of territory " +
//                          "they control. "
// const game7poster = "/img/game7_poster.jpg"
// const game7img0 = "/img/game7_img1.jpg"
// const game7img1 = "/img/game7_img2.jpg"
// const game7img2 = "/img/game7_img3.jpg"
// const game7img3 = "/img/game7_img4.jpg"
// const game7img4 = "/img/game7_img5.jpg"
// const game7img5 = "/img/game7_img6.jpg"
// const game7img6 = "/img/game7_img7.jpg"
// const game7img7 = "/img/game7_img8.jpg"

// //game eight Geoboards
// const game8name = "Geoboards"
// const game8downloadUrl = ""
// const game8trailerUrl = "https://www.youtube.com"
// const game8description = "Geoboards is...."
// const game8poster = "/img/game8_poster.jpg"
// const game8img0 = "/img/game8_img1.jpg"
// const game8img1 = "/img/game8_img2.jpg"
// const game8img2 = "/img/game8_img3.jpg"
// const game8img3 = "/img/game8_img4.jpg"
// const game8img4 = "/img/game8_img5.jpg"
// const game8img5 = "/img/game8_img6.jpg"
// const game8img6 = "/img/game8_img7.jpg"
// const game8img7 = "/img/game8_img8.jpg"
// const game8img8 = "/img/game8_img9.jpg"

// //game einineght SECRET DOOR
// const game9name = "Slice Camera"
// const game9downloadUrl = "https://apps.apple.com/app/id1475716268"
// const game9trailerUrl = "https://www.youtube.com"
// const game9description = "Slice Camera is an app that lets you create photos by combining one row of pixels at a time, building then up to create images with "+
//                          "unique properties. Experiment with different techniques and scenarios to create different effects.  Images created "+
//                          "with slice camera blend the moments together, as each slice is captured one after the other. With a little bit of practice "+
//                          "and experimentation there are all kinds of interesting images that can be pulled out of a moment in time."
// const game9poster = "/img/game9_poster.jpg"
// const game9img0 = "/img/game9_img1.jpg"
// const game9img1 = "/img/game9_img2.jpg"
// const game9img2 = "/img/game9_img3.jpg"
// const game9img3 = "/img/game9_img4.jpg"
// const game9img4 = "/img/game9_img5.jpg"
// const game9img5 = "/img/game9_img6.jpg"
// const game9img6 = "/img/game9_img7.jpg"
// const game9img7 = "/img/game9_img8.jpg"
// const game9img8 = "/img/game9_img9.jpg"



function Games (props){
    return(    
    <div className={ props.darkMode ?  "page pageDark" : "page" }>
        <div className={ props.darkMode ? "myGamePage myArtPageDark" :  "myGamePage" }>    

 {/* <h1 className={props.darkMode ? "h1Dark" : ""}> Apps & Games</h1> */}
               
                {/* SLICE CAMERA  */}
                <GameInfoLandscapeB 
                    darkMode={props.darkMode}
                    gameTitle={game6name}
                    downloadURL={game6downloadUrl}
                    trailerURL={game6trailerUrl}
                    descriptionText={game6description}
                    posterImg={game6poster}
                    preview0={game6img0}
                    preview1={game6img1}
                    preview2={game6img2}
                    preview3={game6img3}
                    preview4={game6img4}
                    preview5={game6img5}
                    // preview6={game6img6}
                    // preview7={game6img7}
                    // preview8={game6img8}
                /> 

{/* <br/>
            {/* <h1 className={props.darkMode ? "h1Dark" : ""}> Apps & Games</h1>   */}
               
                {/* SLICE CAMERA  */}
                {/* <GameInfoLandscapeC 
                    darkMode={props.darkMode}
                    gameTitle={game9name}
                    downloadURL={game9downloadUrl}
                    trailerURL={game9trailerUrl}
                    descriptionText={game9description}
                    posterImg={game9poster}
                    preview0={game9img0}
                    preview1={game9img1}
                    preview2={game9img2}
                    preview3={game9img3}
                    preview4={game9img4}
                    preview5={game9img5}
                    preview6={game9img6}
                    preview7={game9img7}
                    preview8={game9img8}
                />  */}
{/* <br/> */}

                {/* ANGLER  */}
                {/* <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game6name}
                    downloadURL={game6downloadUrl}
                    trailerURL={game6trailerUrl}
                    descriptionText={game6description}
                    posterImg={game6poster}
                    preview0={game6img0}
                    preview1={game6img1}
                    preview2={game6img2}
                    preview3={game6img3}
                    preview4={game6img4}
                    preview5={game6img5}
                    preview6={game6img6}
                    preview7={game6img7}
                /> */}
{/* <br/> */}

                {/* GO BOARD  */}
                {/* <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game7name}
                    downloadURL={game7downloadUrl}
                    trailerURL={game7trailerUrl}
                    descriptionText={game7description}
                    posterImg={game7poster}
                    preview0={game7img0}
                    preview1={game7img1}
                    preview2={game7img2}
                    preview3={game7img3}
                    preview4={game7img4}
                    preview5={game7img5}
                    preview6={game7img6}
                    preview7={game7img7}
                />    */}
           
                {/* SECRET BUTTON TO LOAD THE "SECRET DOOR" WEBPAGE  */}         



                {/* VINECRAFT  */}
                <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game2name}
                    downloadURL={game2downloadUrl}
                    trailerURL={game2trailerUrl}
                    descriptionText={game2description}
                    posterImg={game2poster}
                    preview0={game2img0}
                    preview1={game2img1}
                    preview2={game2img2}
                    preview3={game2img3}
                    preview4={game2img4}
                    preview5={game2img5}
                    preview6={game2img6}
                    preview7={game2img7}
                />
<br/>

                 {/* TORONTO RACCOONS  */}
                 <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game1name}
                    downloadURL={game1downloadUrl}
                    trailerURL={game1trailerUrl}
                    descriptionText={game1description}
                    posterImg={game1poster}
                    preview0={game1img0}
                    preview1={game1img1}
                    preview2={game1img2}
                    preview3={game1img3}
                    preview4={game1img4}
                    preview5={game1img5}
                    preview6={game1img6}
                    preview7={game1img7}
                />
<br/>
                {/* ALLEYCAT  */}
                <GameInfoLandscapeB 
                    darkMode={props.darkMode}
                    gameTitle={game4name}
                    downloadURL={game4downloadUrl}
                    trailerURL={game4trailerUrl}
                    descriptionText={game4description}
                    posterImg={game4poster}
                    preview0={game4img0}
                    preview1={game4img1}
                    preview2={game4img2}
                    preview3={game4img3}
                    preview4={game4img4}
                    preview5={game4img5}
                    preview6={game4img6}
                    preview7={game4img7}
                />
<br/>
                  {/* THROUGHLINE  */}
                  <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game3name}
                    downloadURL={game3downloadUrl}
                    trailerURL={game3trailerUrl}
                    descriptionText={game3description}
                    posterImg={game3poster}
                    preview0={game3img0}
                    preview1={game3img1}
                    preview2={game3img2}
                    preview3={game3img3}
                    preview4={game3img4}
                    preview5={game3img5}
                    preview6={game3img6}
                    preview7={game3img7}
                />
<br/>
                {/* ICEBREAKER  */}
                <GameInfoPortrait 
                    darkMode={props.darkMode}
                    gameTitle={game0name}
                    downloadURL={game0downloadUrl}
                    trailerURL={game0trailerUrl}
                    descriptionText={game0description}
                    posterImg={game0poster}
                    preview0={game0img0}
                    preview1={game0img1}
                    preview2={game0img2}
                    preview3={game0img3}
                    preview4={game0img4}
                    preview5={game0img5}
                    preview6={game0img6}
                    preview7={game0img7}
                />
<br/>     

                {/* SLIPPY CARS  */}
                <GameInfoLandscapeC 
                    darkMode={props.darkMode}
                    gameTitle={game5name}
                    downloadURL={game5downloadUrl}
                    trailerURL={game5trailerUrl}
                    descriptionText={game5description}
                    posterImg={game5poster}
                    preview0={game5img0}
                    preview1={game5img1}
                    preview2={game5img2}
                    preview3={game5img3}
                    preview4={game5img4}
                    preview5={game5img5}
                    preview6={game5img6}
                    preview7={game5img7}
                    preview8={game5img8}
                /> 
<br/>                
                 {/* GEOBOARDS  */}
                 {/* <GameInfoLandscapeC 
                    darkMode={props.darkMode}
                    gameTitle={game8name}
                    downloadURL={game8downloadUrl}
                    trailerURL={game8trailerUrl}
                    descriptionText={game8description}
                    posterImg={game8poster}
                    preview0={game8img0}
                    preview1={game8img1}
                    preview2={game8img2}
                    preview3={game8img3}
                    preview4={game8img4}
                    preview5={game8img5}
                    preview6={game8img6}
                    preview7={game8img7}
                    preview8={game8img8}
                />    */}
                <br/>     
                <br/>    

{/* toggle on or off to show / hide the secret door button in the games page... */}
                {/* <div className="secretHoverDiv">
                    <a href="http://localhost:3000/secretdoor"> <img alt="Secret Door" src={window.location.origin + "/img/secretdoor.svg"} width="75" height="75"/> </a>
                <br/>     
                <br/>     
                <br/>     
                </div> */}


        </div>
    </div>  

)}

export default Games